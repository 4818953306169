import React, { useEffect ,useState,useCallback } from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import { Container, Typography, Grid, Button, Card, CardContent, CardMedia, Box } from '@mui/material';
import keywordsData from './keywords.json';
import { Helmet } from 'react-helmet';
import { useLanguage } from './LanguageContext';
import { useTheme } from './ThemeContext';
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';
import SlowMotionVideoIcon from '@mui/icons-material/SlowMotionVideo';
import MovieIcon from '@mui/icons-material/Movie';

const HomePage = ({ lang }) => {
    const { language } = useLanguage();
    const { theme } = useTheme();
    const [isMobile, setIsMobile] = useState(false);
    const [showAds, setShowAds] = useState(false);
    const [shareData, setShareData] = useState([]);
    const isLight = theme === 'light';
    const heroSecBackgroundColor = isLight ? '#333' : '#fff';

    const navigate = useNavigate(); // Initialize useNavigate

    const handleReqStieData = useCallback(async () => {
        try {
          const token = localStorage.getItem('authToken') || ""
          const response = await fetch('https://samrt-loader.com/kydwon/api/siteInfo' , {
            method: 'GET', // Specify the method
            headers: {
              'X-Authorization':`Bearer ${token}`
            },
          });
          const data = await response.json();
          if (data.success) {
            //setVideoData(response.data.files); // Set videoData state with response data
            setIsMobile(data.is_mobile);
            setShareData(data.share_data);
            if(data.is_mobile){
              localStorage.setItem('isMobile','true')
            } else {
              localStorage.setItem('isMobile','false')
            }
            
            var isShowAds = data.is_show_ads;
            if(isShowAds) {
              setTimeout(() => setShowAds(true), 3000); // Check again after 3 seconds
            } else {
              setShowAds(false);
            }
            localStorage.setItem('share_count',data.share_count)
          }
        } catch (error) {
        }
      }, []); // Remove shareData from the dependencies

      useEffect(() => {
        localStorage.setItem('language', lang);
        const checkIsLogin = localStorage.getItem('isLogin');
        if (checkIsLogin === "true") {
          // Optionally decode token to get user info
          navigate(`../editer`);
        } else {
            handleReqStieData();
        }
    },[handleReqStieData]);

    const handelOpenDownloader = () => {
        navigate(`../downloads`);
    }

    const handelOpenEditer = () => {
        navigate(`../editer`);
    }

    const SEO = ({ title, description, keywords }) => (
        <Helmet>
          <title>{title}</title>
          <meta name="description" content={description} />
          <meta name="keywords" content={keywords} />
          <script type="application/ld+json">
            {`
              {
                "@context": "https://schema.org",
                "@type": "WebSite",
                "name": "${title}",
                "url": "https://samrt-loader.com"
              }
            `}
          </script>
        </Helmet>
      );
  

  return (
    <div
      style={{
        minHeight: '100vh',
        padding: '3rem 0',
      }}
    >
      <Container>
      <SEO 
        title={language === 'ar' ? keywordsData.title_ar : keywordsData.title_en}
        description={language === 'ar' ? keywordsData.description_ar : keywordsData.description_en}
        keywords={keywordsData.keywords.join(', ')}
      /> 
        {/* Hero Section */}
        <div style={{ textAlign: 'center', marginBottom: '3rem', color: heroSecBackgroundColor }}>
          <Typography
            sx={{ fontWeight: 'bold', fontSize: '1.7rem' , marginBottom: '1rem' }}
          >
            {language === 'ar' ? 'إنشاء وتحميل مقاطع فيديو قصيرة ومذهلة لوسائل التواصل' : 'Create & Download Stunning Social Media Videos & Shorts'}
          </Typography>
          <Typography sx={{ fontSize: '1.2rem', color: '#777' }}>
            {language === 'ar' ? 'محرر سهل الاستخدام لإنشاء محتوى عالي الجودة في دقائق.' : 'Easy-to-use editor for creating high-quality content in minutes.'}
          </Typography>
        </div>

        {/* Features Section */}
        <Grid container spacing={3} justifyContent="center" style={{marginTop:50 , marginRight:language === 'ar' ? -12 : -32}}>
        <Grid item xs={12} sm={6} md={4}>
            <Card sx={{ maxWidth: 345, margin: 'auto' }}>
              <DownloadForOfflineIcon
                style={{ width:'100%' ,height: 140,color:'#e66c82b5' }}
                />
              <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                  {language === 'ar' ? 'أداة تحميل الفيديوهات' : 'Download Videos tools'}
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  {language === 'ar' ? 'قم بتنزيل وتعديل الفيديوهات من السوشيال ميديا مثل فيس بوك أو إنستجرام أو سناب شات أو تيك توك أو كاواي أو يوتيوب أو تويتر وغيرها من المواقع التي تدعمها المنصة  بتنسيق HD.' : 'Download & Edit videos from social media such as Snapchat, TikTok, Kawi, Facebook, YouTube, twiter or Instagram and alot of site list that we support in HD and ready-to-post formats for any platform.'}
                </Typography>
                <Button
                 onClick={handelOpenDownloader}
                  aria-label='get started video downloader'
                  variant="contained"
                  color='secondary'
                  sx={{ marginTop: '1rem', '&:hover': { backgroundColor: '#f50057' } }}
                >
                  {language === 'ar' ? 'تحميل الفيديوهات' : 'Download Now'}
                </Button>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Card sx={{ maxWidth: 345, margin: 'auto' }}>
              <MovieIcon
                style={{ width:'100%' ,height: 140,color:'#e66c82b5' }}
                />
              <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                  {language === 'ar' ? 'تعديل الفيديوهات' : 'Edit Videos'}
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  {language === 'ar' ? 'قم بتعزيز مقاطع الفيديو الخاصة بك باستخدام أدوات وميزات بديهية مثل القص و اللصق والفلترة و التحرير وإضافة نصوص على الفيديو والمزيد من أدوات المحرر.' : 'Enhance your videos with intuitive tools and features like cropping, trimming, filters, paste, filter, edit, add text to video and more editor tools.'}
                </Typography>
                <Button
                  onClick={handelOpenEditer}
                  aria-label='get started'
                  variant="contained"
                  color='secondary'
                  sx={{ marginTop: '1rem', '&:hover': { backgroundColor: '#f50057' } }}
                >
                  {language === 'ar' ? 'بدء الإستخدام' : 'Get Started'}
                </Button>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            <Card sx={{ maxWidth: 345, margin: 'auto' }}>
              <SlowMotionVideoIcon
                style={{ width:'100%' ,height: 140,color:'#e66c82b5' }}
                />
              <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                  {language === 'ar' ? 'إنشاء محتوى' : 'Create Content'}
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  {language === 'ar' ? 'قم بتصميم وإنشاء مقاطع فيديو قصيرة وجذابة لتعزيز حضورك على وسائل التواصل الاجتماعي , إنشاء ستكيرات , إضافة مؤثرات والمزيد من خصائص التحرير.' : 'Design and create engaging short-form videos to boost your social media presence, Create stickers, Add effects and more editing video tools features.'}
                </Typography>
                <Button
                  onClick={handelOpenEditer}
                  aria-label='get started editer'
                  variant="contained"
                  color='secondary'
                  sx={{ marginTop: '1rem', '&:hover': { backgroundColor: '#f50057' } }}
                >
                  {language === 'ar' ? 'بدء إنشاء محتوى' : 'Start Creating'}
                </Button>
              </CardContent>
            </Card>
          </Grid>
        </Grid>

        <Box width="100%"  marginTop={14}>
      <Grid>
        <Grid container spacing={2} sx={{ justifyContent: 'center' }}>
            <Grid>
            <Card sx={{ maxWidth: 50, maxHeight: 100 }}>
            <CardMedia 
              component="img"
              height="50"
              image={'https://samrt-loader.com/social_icons/facebook.png'}
              alt={`facebook`}
            />
          </Card>
            </Grid>
            <Grid>
            <Card sx={{ maxWidth: 50, maxHeight: 100 }}>
            <CardMedia 
              component="img"
              height="50"
              image={'https://samrt-loader.com/social_icons/instagram.png'}
              alt={`instagram`}
            />
          </Card>
            </Grid>
            <Grid>
            <Card sx={{ maxWidth: 50, maxHeight: 100 }}>
            <CardMedia 
              component="img"
              height="50"
              image={'https://samrt-loader.com/social_icons/threads.png'}
              alt={`Threads`}
            />
          </Card>
            </Grid>
            <Grid>
            <Card sx={{ maxWidth: 50, maxHeight: 100 }}>
            <CardMedia 
              component="img"
              height="50"
              image={'https://samrt-loader.com/social_icons/tiktok.png'}
              alt={`tiktok`}
            />
          </Card>
            </Grid>
            <Grid>
            <Card sx={{ maxWidth: 50, maxHeight: 100 }}>
            <CardMedia 
              component="img"
              height="50"
              image={'https://samrt-loader.com/social_icons/snapchat.png'}
              alt={`snapchat`}
            />
          </Card>
            </Grid>
            <Grid>
            <Card sx={{ maxWidth: 50, maxHeight: 100 }}>
            <CardMedia 
              component="img"
              height="50"
              image={'https://samrt-loader.com/social_icons/twitter.png'}
              alt={`twitter`}
            />
          </Card>
            </Grid>
            <Grid>
            <Card sx={{ maxWidth: 50, maxHeight: 100 }}>
            <CardMedia 
              component="img"
              height="50"
              image={'https://samrt-loader.com/social_icons/pinterest.png'}
              alt={`pinterest`}
            />
          </Card>
            </Grid>
            <Grid>
            <Card sx={{ maxWidth: 50, maxHeight: 100 }}>
            <CardMedia 
              component="img"
              height="50"
              image={'https://samrt-loader.com/social_icons/kwai.webp'}
              alt={`kwai`}
            />
          </Card>
            </Grid>
            <Grid>
            <Card sx={{ maxWidth: 50, maxHeight: 100 }}>
            <CardMedia 
              component="img"
              height="50"
              image={'https://samrt-loader.com/social_icons/youtube.png'}
              alt={`youtube`}
            />
          </Card>
            </Grid>
        </Grid>
      </Grid>
      </Box>
      </Container>
    </div>
  );
};

export default HomePage;
